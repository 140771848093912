<script setup lang="ts">
import type { PropType } from 'vue';
type FieldType =
  | 'year'
  | 'years'
  | 'month'
  | 'date'
  | 'dates'
  | 'datetime'
  | 'week'
  | 'datetimerange'
  | 'daterange'
  | 'monthrange';

type Format = 'MM/DD/YYYY' | 'DD/MM/YYYY';
const emits = defineEmits(['update:modelValue']);

const props = defineProps({
  type: {
    type: String as PropType<FieldType>,
    default: 'date',
  },
  modelValue: {
    type: Date,
  },
  label: {
    type: String,
  },
  placeholder: {
    type: String,
  },
  disabled: {
    type: Boolean,
  },
  clearable: {
    type: Boolean,
  },
  required: {
    type: Boolean,
  },

  format: {
    type: String as PropType<Format>,
    default: 'DD/MM/YYYY',
  },
  validation: {
    type: Object,
    default: () => {},
  },
});

const localValue = ref(props.modelValue);
const shortcuts = [
  {
    text: 'La semaine dernière',
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      return [start, end];
    },
  },
  {
    text: 'Le mois dernier',
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      return [start, end];
    },
  },
  {
    text: 'Les 3 derniers mois',
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      return [start, end];
    },
  },
];
</script>

<template>
  <div class="base-datepicker flex flex-col">
    <label class="font-medium" v-if="props.label"
      >{{ label }} <span v-if="props.required">*</span>
    </label>

    <el-date-picker
      v-model="localValue"
      :type="props.type"
      :placeholder="props.placeholder"
      :required="props.required"
      :disabled="props.disabled"
      :clearable="props.clearable"
      :format="props.format"
      :shortcuts="shortcuts"
      size="large"
      range-separator="au"
      start-placeholder="Date de début"
      end-placeholder="Date de fin"
      value-format="YYYY-MM-DD"
      @change="(v) => $emit('update:modelValue', v)"
    />
    <div v-if="props.validation?.$invalid">
      <span class="text-red-500 text-sm">{{
        props.validation.$errors?.[0]?.$message
      }}</span>
    </div>
  </div>
</template>
